.Btn {
  display: inline-block;
  transition: all 0.15s;
  border-radius: 50px;
  font-weight: 400;
  line-height: 1;
  text-align: center;
  white-space: nowrap; }
  .Btn:hover {
    text-decoration: none; }
  .Btn.has-border {
    border-width: 2px;
    border-style: solid; }
  .Btn > span {
    display: block;
    border-radius: 50px;
    font-weight: 400; }

.Btn--background {
  background: #FFF; }

.Btn--m > span {
  padding: 12px 20px;
  font-size: 12px; }

.Btn--m.has-border > span {
  padding: 10px 20px; }

.Btn--l > span {
  padding: 16px 35px;
  font-size: 14px; }

.Btn--l.has-border > span {
  padding: 14px 35px; }

.Btn--white {
  border-color: rgba(255, 255, 255, 0.3);
  color: #FFF; }
  .Btn--white:hover {
    background: #FFF;
    color: #333; }

.Btn--green {
  border-color: #8EB83F;
  color: #8EB83F; }
  .Btn--green:hover {
    background: #8EB83F;
    color: #FFF; }
  .Btn--green.is-highlight {
    background: #8EB83F;
    color: #FFF; }
    .Btn--green.is-highlight:hover {
      background: #FFF;
      color: #8EB83F; }

.Btn--blue {
  border-color: #397EB9;
  color: #397EB9; }
  .Btn--blue:hover {
    background: #397EB9;
    color: #FFF; }
  .Btn--blue.is-highlight {
    background: #397EB9;
    color: #FFF; }
    .Btn--blue.is-highlight:hover {
      background: #FFF;
      color: #397EB9; }
