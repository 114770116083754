.Navbar::before,
.Navbar::after {
  content: " ";
  display: table; }


.Navbar::after {
  clear: both; }

.Navbar {
  padding: 20px 0;
  text-align: center; }

.Navbar--left {
  text-align: left; }

.Navbar-brand {
  display: flex;
  justify-content: center;
  max-width: 92px;
  height: 48px;
  margin: 0 auto;
  margin-bottom: 40px; }
  .Navbar-brand img {
    height: 100%; }

.Navbar-menu {
  float: right; }

.Navbar-menuItem {
  position: relative;
  margin: -20px 0 -20px 20px;
  padding: 20px 0;
  float: left;
  color: #999; }
  .Navbar-menuItem:first-child {
    margin-left: 0; }
  .Navbar-menuItem.is-selected {
    color: #333; }
    .Navbar-menuItem.is-selected::after {
      content: "";
      display: block;
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 3px;
      background: #397DBA; }

.Navbar-text {
  margin-top: 18px;
  color: rgba(255, 255, 255, 0.5);
  font-size: 16px; }

.Navbar-textLink {
  color: rgba(255, 255, 255, 0.5);
  text-decoration: underline; }
  .Navbar-textLink:hover {
    color: WHITE; }

.Navbar-link {
  color: #FFF; }
