.Browser {
  width: 560px;
  height: 430px;
  margin: 20px 0;
  border-radius: 4px;
  background: #F2F2F2;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3), 0 2px 10px 6px rgba(0, 0, 0, 0.05); }

.Browser--oneclick {
  height: 423px;
  margin-top: 0; }
  .Browser--oneclick .Browser-media {
    height: 357px; }

.Browser-actions {
  width: auto;
  padding: 10px;
  overflow: hidden;
  list-style: none; }

.Browser-actionsItem {
  width: 9px;
  height: 9px;
  margin-right: 5px;
  float: left;
  border-radius: 50%;
  background: #DDD; }

.Browser-bar {
  position: relative;
  padding: 10px;
  background: #DDD; }
  .Browser-bar::before, .Browser-bar::after {
    content: "";
    position: absolute;
    top: -24px;
    width: 57px;
    height: 27px;
    border-radius: 8px;
    background: #DDD; }
  .Browser-bar::after {
    left: 110px;
    -webkit-transform: matrix(1, 0, 0.45, 1, -32, 7.133);
    transform: matrix(1, 0, 0.45, 1, -32, 7.133); }
  .Browser-bar::before {
    left: 90px;
    -webkit-transform: matrix(1, 0, 0.45, 1, -32, 7.133);
    transform: matrix(1, 0, -0.45, 1, -32, 7.133); }

.Browser-address {
  height: 17px;
  border-radius: 12px;
  background: #FFF;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.11); }

.Browser-media {
  position: relative;
  height: 364px;
  overflow: hidden;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  background: #FFF; }

.Progress {
  position: absolute;
  z-index: 3;
  top: 0;
  left: 0;
  width: 0;
  height: 2px;
  -webkit-animation: animationProgress 5s linear 0s infinite;
  animation: animationProgress 5s linear 0s infinite;
  background: #7ED321; }
