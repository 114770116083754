.FlashMessage {
  width: 100%;
  background: #FFF7DF; }

.FlashMessage > .u-inner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 80px;
  line-height: 24px; }

.FlashMessage.FlashMessage--inDialogWithFiltersNavListing {
  position: fixed;
  z-index: 2;
  top: 157px;
  border-bottom: 1px solid #dddddd; }
  .FlashMessage.FlashMessage--inDialogWithFiltersNavListing > .u-inner {
    justify-content: center; }

.FlashMessage--success {
  background: #E8F0D6; }

.FlashMessage--error {
  background: #FFDBDB; }

.FlashMessage--warning {
  background: #FFFFD9; }

.FlashMessage--main {
  background: #F0F7FC; }

.FlashMessage--text {
  font-family: 'Montserrat';
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 16px;
  text-align: right; }

.FlashMessage-info {
  color: #333333;
  font-size: 15px; }

.FlashMessage-detail {
  color: #999999;
  font-size: 13px; }

.FlashMessage-info--larger {
  max-width: 720px; }

.FlashMessage-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%; }

.FlashMessage--button {
  box-sizing: border-box;
  height: 28px;
  padding: 0 20px;
  border: 1px solid white;
  border-radius: 4px;
  border-color: #0090d7;
  outline: none;
  background-color: #0090d7;
  color: #FFF;
  font-size: 12px;
  font-weight: 500;
  line-height: 15px;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase; }
  .FlashMessage--button:hover {
    background-color: #007fbd; }
