.u-inner {
  position: relative;
  z-index: 1;
  width: 100%;
  max-width: 960px;
  margin: 0 auto;
  padding: 0 20px; }

.u-left {
  float: left; }

.u-right {
  float: right; }

.u-pa {
  position: absolute; }

.u-pr {
  position: relative; }

.u-ov {
  overflow: hidden; }

.u-iblock {
  display: inline-block; }

.u-ilist > li {
  display: inline-block; }

.u-malign {
  vertical-align: middle; }

.u-talign {
  vertical-align: top; }

.u-balign {
  vertical-align: bottom; }

.u-txt-center {
  text-align: center; }

.u-txt-left {
  text-align: left; }

.u-txt-right {
  text-align: right; }

.u-tupper {
  text-transform: uppercase; }

.u-valign::before {
  content: "";
  display: inline-block;
  height: 100%;
  margin-left: -5px;
  vertical-align: middle; }

.u-vspace-xl {
  margin-bottom: 60px; }

.u-vspace-l {
  margin-bottom: 40px; }

.u-vspace-m {
  margin-bottom: 25px; }

.u-vspace-s {
  margin-bottom: 12px; }

.u-vspace-xs {
  margin-bottom: 8px; }

.u-lspace-xl {
  margin-left: 60px; }

.u-lspace-l {
  margin-left: 40px; }

.u-lspace-m {
  margin-left: 25px; }

.u-lspace-s {
  margin-left: 12px; }

.u-lspace-xs {
  margin-left: 8px; }

.u-rspace-xl {
  margin-right: 60px; }

.u-rspace-l {
  margin-right: 40px; }

.u-rspace-m {
  margin-right: 25px; }

.u-rspace-s {
  margin-right: 12px; }

.u-rspace-xs {
  margin-right: 8px; }

.u-tspace-xl {
  margin-top: 60px; }

.u-tspace-l {
  margin-top: 40px; }

.u-tspace-m {
  margin-top: 25px; }

.u-tspace-s {
  margin-top: 12px; }

.u-tspace-xs {
  margin-top: 8px; }

.u-img-circle {
  border-radius: 50%; }
