* {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box; }

html,
body {
  min-width: 360px;
  height: 100%;
  margin: 0;
  padding: 0; }

body {
  background-color: #FFF;
  color: #666;
  font-family: "ProximaNova", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 15px;
  font-weight: 300;
  line-height: 25px;
  -webkit-text-rendering: optimizeLegibility;
  text-rendering: optimizeLegibility; }

a {
  color: #397DBA;
  text-decoration: none; }
  a:hover {
    text-decoration: underline; }

strong {
  font-weight: 700; }

em {
  font-style: italic; }

abbr {
  border-bottom: 1px dotted #DDD; }

button {
  margin: 0;
  border: 0;
  outline: none;
  cursor: pointer; }
